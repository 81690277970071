export const isBrowser = () => typeof window !== "undefined";

export const getObject = (array, key, value) => {
  var o;
  array.some(function iter(a) {
    if (a[key] === value) {
      o = a;
      return true;
    }
    return Array.isArray(a.children) && a.children.some(iter);
  });
  return o;
};

export const loadScript = (
  scriptUrl,
  insertIntoHead,
  async = false,
  insertEndBody = false,
  innerHTML = ""
) => {
  const body = document.body.firstChild;
  const head = document.head;
  const script = document.createElement("script");
  // const avantis = document.getElementById("avantisContainerJS");

  if (scriptUrl) {
    script.src = scriptUrl;
  }

  if (innerHTML) {
    script.innerHTML = innerHTML;
  }

  if (async) {
    script.async = true;
  }

  if (insertIntoHead) {
    head.appendChild(script);
  }

  if (insertEndBody) {
    body.appendChild(script);
  }

  // loads script in avantis Div, remember to add argument to function when uncommenting
  // if (insertAvantis) {
  //   // Clear out script tag if one is present
  //   avantis.innerHTML = "";
  //   avantis.appendChild(script);
  //   // Add ID to script tag
  //   var avantisScript = avantis.getElementsByTagName('script')[0];
  //   avantisScript.setAttribute("id", "avantisJS");
  // }

  if (!insertEndBody && !insertIntoHead) {
    body.parentNode.insertBefore(script, body);
  }

  if (scriptUrl) {
    return new Promise((res, rej) => {
      script.onload = function () {
        res();
      };
      script.onerror = function () {
        rej();
      };
    });
  }
};

export const loadNoScript = (insertIntoHead, innerHTML = "") => {
  const tag = document.createElement("noscript");
  tag.innerHTML = innerHTML;

  if (insertIntoHead) {
    document.head.appendChild(tag);
  } else {
    document.body.appendChild(tag);
  }
};

export const isMobile = () => {
  // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return isBrowser() && window.screen.width < 812;
};

export const isMobilePredicate = () => {
  return window.screen.width < 812;
};

/**
 * Returns lowercase abbreviation of site name
 * name must have space between each word
 * that will be abbreviated
 * @param {string} siteName
 * @returns string
 */
export function makeAbbr(siteName) {
  const words = siteName.split(" ");
  let abbr = "";
  for (const word of words) {
    abbr += word.substring(0, 1);
  }
  return abbr.toLowerCase();
}
export const isDesktop = () => window.screen.width > 812;

export const checkUtmSourceStartsWith = (preffix) => {
  if (isBrowser()) {
    if (window.localStorage.getItem("utm_source") == null) {
      return null;
    } else {
      return window.localStorage
        .getItem("utm_source")
        .toLowerCase()
        .startsWith(preffix);
    }
  }
};

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const DebugModeLog = (message, obj = false) => {
  const isDebug = getParameterByName("debug");
  if (isDebug && message && !obj) console.log("TC Debug: " + message);
  if (isDebug && message && obj) console.log("TC Debug: " + message, obj);
};

/**
 * Toggles class on chosen element to show/hide
 * Pass an ID or class name as selector and 'show' or 'hide' as toggle
 */
export function toggleElement(selector, toggle) {
  const element = document.querySelector(selector);
  if (toggle === "show" && element.classList.contains("d-none")) {
    element.classList.remove("d-none");
  }
  if (toggle === "hide" && !element.classList.contains("d-none")) {
    element.classList.add("d-none");
  }
}

/**
 * Generate random number between 0 and 100 (exclusive)
 * @returns int
 */
export const generateRandomNum = () => {
  return Math.random();
};

/**
 * Sets Ab test cookie value
 * @param {string} abTestVal
 */
export const setAbTestVal = (abTestVal) => {
  localStorage.setItem("abtest", abTestVal);
};

/**
 * Splits site traffic according
 * to random number
 * thens sets abtest value
 * @returns string
 */
export const runAbTest = (
  numOne,
  numTwo,
  numThree,
  numFour,
  numFive,
  numSix
) => {
  let randomNum = generateRandomNum();
  if (isDesktop) {
    if (randomNum < numOne) {
      DebugModeLog("abtest a");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.a`);
    } else if (randomNum >= numOne && randomNum < numTwo) {
      DebugModeLog("abtest b");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.c`);
    } else if (randomNum >= numTwo && randomNum < numThree) {
      DebugModeLog("abtest c");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.c`);
    } else if (randomNum >= numThree && randomNum < numFour) {
      DebugModeLog("abtest d");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.d`);
    } else if (randomNum >= numFour && randomNum < numFive) {
      DebugModeLog("abtest e");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.e`);
    } else if (randomNum >= numFive && randomNum < numSix) {
      DebugModeLog("abtest y");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.y`);
    } else {
      DebugModeLog("abtest z");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.z`);
    }
  } else {
    if (randomNum < numOne) {
      DebugModeLog("abtest a");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.a`);
    } else if (randomNum >= numOne && randomNum < numTwo) {
      DebugModeLog("abtest b");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.c`);
    } else if (randomNum >= numTwo && randomNum < numThree) {
      DebugModeLog("abtest c");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.c`);
    } else if (randomNum >= numThree && randomNum < numFour) {
      DebugModeLog("abtest d");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.d`);
    } else if (randomNum >= numFour && randomNum < numFive) {
      DebugModeLog("abtest e");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.e`);
    } else if (randomNum >= numFive && randomNum < numSix) {
      DebugModeLog("abtest y");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.y`);
    } else {
      DebugModeLog("abtest z");
      setAbTestVal(`ab.${process.env.GATSBY_SHORT_DOMAIN}.z`);
    }
  }
  return localStorage.getItem("abtest");
};
